import {
  FETCH_CARD_DETAILS_START,
  FETCH_CARD_DETAILS_SUCCESS,
  FETCH_CARD_DETAILS_FAILURE,
  DELETE_CARD_START,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  SELECT_DEFAULT_CARD_START,
  SELECT_DEFAULT_CARD_SUCCESS,
  SELECT_DEFAULT_CARD_FAILURE,
  FETCH_PAYBOX_CARD_DETAILS_START,
  FETCH_PAYBOX_CARD_DETAILS_SUCCESS,
  FETCH_PAYBOX_CARD_DETAILS_FAILURE,
  DELETE_PAYBOX_CARD_START,
  DELETE_PAYBOX_CARD_SUCCESS,
  DELETE_PAYBOX_CARD_FAILURE,
  SELECT_PAYBOX_DEFAULT_CARD_START,
  SELECT_PAYBOX_DEFAULT_CARD_SUCCESS,
  SELECT_PAYBOX_DEFAULT_CARD_FAILURE,
  ADD_PAYBOX_CARD_START,
  ADD_PAYBOX_CARD_SUCCESS,
  ADD_PAYBOX_CARD_FAILURE,
} from "./ActionConstant";

// Get Card Details actions.

export function fetchCardDetailsStart(data) {
  return {
    type: FETCH_CARD_DETAILS_START,
    data,
  };
}

export function fetchCardDetailsSuccess(data) {
  return {
    type: FETCH_CARD_DETAILS_SUCCESS,
    data,
  };
}

export function fetchCardDetailsFailure(error) {
  return {
    type: FETCH_CARD_DETAILS_FAILURE,
    error,
  };
}
//  Delete card actions.

export function deleteCardStart(data) {
  return {
    type: DELETE_CARD_START,
    data,
  };
}

export function deleteCardSuccess(data) {
  return {
    type: DELETE_CARD_SUCCESS,
    data,
  };
}

export function deleteCardFailure(error) {
  return {
    type: DELETE_CARD_FAILURE,
    error,
  };
}

// Make default card actions.

export function selectDefaultCardStart(data) {
  return {
    type: SELECT_DEFAULT_CARD_START,
    data,
  };
}

export function selectDefaultCardSuccess(data) {
  return {
    type: SELECT_DEFAULT_CARD_SUCCESS,
    data,
  };
}

export function selectDefaultCardFailure(error) {
  return {
    type: SELECT_DEFAULT_CARD_FAILURE,
    error,
  };
}


export function fetchPayboxCardDetailsStart(data) {
  return {
    type: FETCH_PAYBOX_CARD_DETAILS_START,
    data,
  };
}

export function fetchPayboxCardDetailsSuccess(data) {
  return {
    type: FETCH_PAYBOX_CARD_DETAILS_SUCCESS,
    data,
  };
}

export function fetchPayboxCardDetailsFailure(error) {
  return {
    type: FETCH_PAYBOX_CARD_DETAILS_FAILURE,
    error,
  };
}

//  Delete card actions.

export function deletePayboxCardStart(data) {
  return {
    type: DELETE_PAYBOX_CARD_START,
    data,
  };
}

export function deletePayboxCardSuccess(data) {
  return {
    type: DELETE_PAYBOX_CARD_SUCCESS,
    data,
  };
}

export function deletePayboxCardFailure(error) {
  return {
    type: DELETE_PAYBOX_CARD_FAILURE,
    error,
  };
}

// Make default card actions.

export function selectPayboxDefaultCardStart(data) {
  return {
    type: SELECT_PAYBOX_DEFAULT_CARD_START,
    data,
  };
}

export function selectPayboxDefaultCardSuccess(data) {
  return {
    type: SELECT_PAYBOX_DEFAULT_CARD_SUCCESS,
    data,
  };
}

export function selectPayboxDefaultCardFailure(error) {
  return {
    type: SELECT_PAYBOX_DEFAULT_CARD_FAILURE,
    error,
  };
}


export function addPayboxCardStart(data) {
  return {
    type: ADD_PAYBOX_CARD_START,
    data,
  };
}

export function addPayboxCardSuccess(data) {
  return {
    type: ADD_PAYBOX_CARD_SUCCESS,
    data,
  };
}

export function addPayboxCardFailure(error) {
  return {
    type: ADD_PAYBOX_CARD_FAILURE,
    error,
  };
}
