export const apiConstants = {
  // Common variables

  LOGIN_BY: "manual",

  DEVICE_TYPE: "web",

  DEVICE_TOKEN: "123456",

  ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

  google_api_key: "AIzaSyAfDkN9CGZJnwWXA93dH1EUnLgDkA-ohKg",

  settingsUrl: "https://cms-jim.fansforx.org/api/user/get_settings_json",
};
