import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Tabs,
  Table,
  Tab,
  Badge,
  Button,
} from "react-bootstrap";
import "./PaymentsIndex.css";
import { fetchMySubscriptionStart,subscriptionAutoRenewalUpdateStart } from "../../../store/actions/SubscriptionAction";
import configuration from "react-global-configuration";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { translate, t } from "react-multi-lang";
import { 
	fetchUserDetailsStart,
	updateAutoRenewalStart,
 } from "../../../store/actions/UserAction";

const SubscriptionsIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchMySubscriptionStart());
    props.dispatch(fetchUserDetailsStart());
  }, []);

  console.log(props.subscriptions);

  return (
    <div className="payment-sec">
      <Container>
        <Row>
          <Col sm={12} md={12} xl={8}>
            <Link
              className="bookmarkes-list notify-title back-button"
              onClick={() => props.history.goBack()}
            >
              <img
                src={window.location.origin + "/assets/images/icons/back.svg"}
                className="svg-clone"
              />
              <h3 className="ml-2 mb-0">{t("payments")}</h3>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="payment-tabs-card">
              <div className="flex-content">
                <h2>{t("statements")}</h2>
                <Button
                    className="send-withdraw-btn"
                    onClick={() =>
                        props.dispatch(
                            updateAutoRenewalStart()
                        )
                    }
                >
                  {props.profile.data.is_auto_renewal_enabled == 1 ? t("disable_autorenewal") : t("enable_autorenewal")}
                </Button>
              </div>
              <Tabs defaultActiveKey="earnings" id="uncontrolled-tab-example">
                <Tab eventKey="earnings" title={t("current_subscription")}>
                  <div className="payment-tabs-content">
                    {props.subscriptions.loading ? (
                      t("loading")
                    ) : props.subscriptions.data.user_subscription_payments.length > 0 ? (
                      <Table borderedless responsive>
                        <thead>
                          <tr className="bg-white">
                            <th>{t("date")}</th>
                            <th>{t("to_user")}</th>
                            <th>{t("mode")}</th>
                            <th>{t("amount")}</th>
                            <th>{t("service_fee")}</th>
                            <th>{t("plan")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.subscriptions.data.user_subscription_payments.map((history) => (
                              history.is_current_subscription == 1 ? (
                            <tr>
                              <td>{history.paid_date}</td>
                                <td>{history.to_user ? history.to_user.name : "N/A"}</td>
                                <td>{history.payment_mode}</td>
                                <td>{history.amount_formatted}</td>
                                <td>{history.admin_amount_formatted}</td>
                                <td>{history.plan_text_formatted}</td>
                                <td>
                                    <Button
                                        className="auto-renewal"
                                        onClick={() =>
                                            props.dispatch(
                                                subscriptionAutoRenewalUpdateStart({
                                                    user_subscription_payment_id:
                                                    history.user_subscription_payment_id,
                                                })
                                            )
                                        }
                                    >
                                        {history.is_auto_renewal_enabled == 1 ? t("disable_autorenewal") : t("enable_autorenewal")}
                                    </Button>
                                </td>
                            </tr>
                          ) : null))}
                        </tbody>
                      </Table>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </Tab>

                <Tab eventKey="payments" title={t("history")}>
                  <div className="payment-tabs-content">
                    {props.subscriptions.loading ? (
                      t("loading")
                    ) : props.subscriptions.data.user_subscription_payments.length > 0 ? (
                      <Table borderedless responsive>
                        <thead>
                            <tr className="bg-white">
                                <th>{t("date")}</th>
                                <th>{t("to_user")}</th>
                                <th>{t("mode")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("service_fee")}</th>
                                <th>{t("plan")}</th>
                                <th>{t("is_current_subscription")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.subscriptions.data.user_subscription_payments.map(
                            (subscription) => (
                            <tr>
                                <td>{subscription.paid_date}</td>
                                <td>{subscription.to_user ? subscription.to_user.name : "N/A"}</td>
                                <td>{subscription.payment_mode}</td>
                                <td>{subscription.amount_formatted}</td>
                                <td>{subscription.admin_amount_formatted}</td>
                                <td>{subscription.plan_text_formatted}</td>
                                <td>{subscription.is_current_subscription == 1 ? (
                                        <Badge className="confirmed-badge">
                                            {t("yes")}
                                        </Badge>
									) : (
										<Badge className="unconfirmed-badge">
                                            {t("no")}
                                        </Badge>
									)}
                                </td>
                            </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
    subscriptions: state.subscriptions.mySubscription,
    profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SubscriptionsIndex));
