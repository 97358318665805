import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Modal,
  InputGroup,
  FormControl,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import api from "../../Environment";
import { connect } from "react-redux";
import { addPayboxCardStart } from "../../store/actions/CardsAction";
import { translate, t } from "react-multi-lang";

const PayboxPaymentAddCardModal = (props) => {
  
    const [inputData, setInputData] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(addPayboxCardStart(inputData));
        props.closePaymentAddCardModal();
    };

    return (
        <>
        <Modal
            className="modal-dialog-center payment-add-card-modal"
            size="md"
            centered
            show={props.paymentAddCard}
            onHide={props.closePaymentAddCardModal}
        >
            {props.paymentAddCard === true ? 
            
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{t("add_paybox_card")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div>
                            <Form onSubmit={handleSubmit}>
                                <Col md={6}>
                                    <Form.Group controlId="formHorizontalNickname">

                                        <Form.Control
                                            type="number"
                                            placeholder={t("card_number")}
                                            value={inputData.card_number}
                                            name="card_number"
                                            onChange={(event) => {
                                                setInputData({
                                                    ...inputData,
                                                    card_number: event.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group controlId="formHorizontalAccountNumber">
                                        <Form.Control
                                            type="number"
                                            placeholder={t("expiry_date")}
                                            value={inputData.expiry_date}
                                            name="expiry_date"
                                            max='4'
                                            onChange={(event) => {
                                                setInputData({
                                                ...inputData,
                                                expiry_date: event.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group controlId="formHorizontalAccountNumber">
                                        <Form.Control
                                            type="number"
                                            placeholder={t("cvv")}
                                            value={inputData.cvv}
                                            name="cvv"
                                            maxLength="4"
                                            onChange={(event) => {
                                                setInputData({
                                                ...inputData,
                                                cvv: event.currentTarget.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                
                            </Form>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn btn-danger width-btn"
                        data-dismiss="modal"
                        onClick={props.closePaymentAddCardModal}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        type="submit"
                        className="btn btn-success width-btn"
                        data-dismiss="modal"
                        onClick={handleSubmit}
                        disabled={props.cards.buttonDisable}
                    >
                    {props.cards.loadingButtonContent !== null
                      ? props.cards.loadingButtonContent
                      : t("submit")}
                    </Button>
                </Modal.Footer>
            </Form>
            : null}
        </Modal>
    </>
    );
};

const mapStateToPros = (state) => ({
    cards: state.cards.payboxAddCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(PayboxPaymentAddCardModal));
